import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

const Card = ({children, className}) => {
  return (
    <div
      className={classNames('Card', {
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

const CardSelect = ({icon, title, className, isSelected, onClick}) => {
  return (
    <div
      onClick={onClick}
      className={classNames('Card__select', {
        [className]: className,
        Card__select__active: isSelected,
      })}
    >
      {icon}
      <h4>{title}</h4>
    </div>
  )
}

CardSelect.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  isSelected: PropTypes.boolean,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
}

const CardItem = ({icon, title, body, className}) => {
  return (
    <div
      className={classNames('Card__item', {
        [className]: className,
      })}
    >
      {icon}
      <div>{title}</div>
      {Array.isArray(body) ? (
        body.map((paragraph, index) => (
          <React.Fragment key={`${index}`}>
            <p>{paragraph}</p>
            {index < body.length - 1 ? <br /> : null}
          </React.Fragment>
        ))
      ) : (
        <p dangerouslySetInnerHTML={{__html: body}}></p>
      )}
    </div>
  )
}

CardItem.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
}

export {Card, CardItem, CardSelect}
