import * as React from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import {typewriter} from '@lib/helpers'
import {Card, CardItem} from '@components/Card'
import {BlogCarousel} from '@components/BlogCarousel'
import {IconViews, IconDataQuality} from '@components/Icon'

const MainLayout = dynamic(() => import('@components/MainLayout'))
const FeatureProjects = dynamic(() => import('@components/FeatureProjects'))
const Meta = dynamic(() => import('@components/Meta'))

import {logEvent} from '@lib/gtm'

export default function Index() {
  const preRef = React.useRef()
  const codeString = `<strong>detector.detect_faces</strong>(img)
[
    {
        '<strong>box</strong>': [277, 90, 48, 63],
        '<strong>keypoints</strong>':
        {
            '<strong>nose</strong>': (303, 131),
            '<strong>mouth_right</strong>': (313, 141),
            '<strong>right_eye</strong>': (314, 114),
            '<strong>left_eye</strong>': (291, 117),
            '<strong>mouth_left</strong>': (296, 143)
        },
        '<strong class="hilight">confidence</strong>': 0.99851983785629272
    }
] `

  const services = [
    {
      icon: <IconViews />,
      title: 'จัดหมวดหมู่ข้อมูล',
      body: 'จัดระบบข้อมูลจำนวนมากช่วยให้สะดวกและรวดเร็วในการนำไปใช้ต่อ ด้วยบริการ Data Categorization',
    },
    {
      icon: <IconDataQuality />,
      title: 'ตรวจสอบความถูกต้อง',
      body: 'ตรวจสอบความถูกต้องและแม่นยำเพื่อเพิ่มความน่าเชื่อถือในข้อมูล ด้วยระบบ Data Labeling',
    },
  ]

  const clients = [
    {
      key: 'jsw',
      width: '171px',
    },
    {
      key: 'agoda',
      width: '136px',
    },
    {
      key: 'homepro',
      width: '166px',
    },
    {
      key: 'scb',
      width: '137px',
    },
    // {
    //   key: 'bitkub',
    //   width: '209px',
    // },
    {
      key: 'aka',
      width: '207px',
    },
    {
      key: 'zen',
      width: '121px',
    },
    {
      key: 'onthetable',
      width: '140px',
    },
    {
      key: 'pioneerthailand',
      width: '182px',
    },
    {
      key: 'aphaiphubet',
      width: '164px',
    },
    {
      key: 'mezzo',
      width: '110px',
    },
    {
      key: 'cotto',
      width: '140px',
    },
    {
      key: 'techsauce',
      width: '164px',
    },
    {
      key: 'atimedesign',
      width: '140px',
    },
    {
      key: 'tog',
      width: '140px',
    },
    {
      key: 'decr',
      width: '133px',
    },
    {
      key: 'zortout',
      width: '189px',
    },
    {
      key: 'denso',
      width: '174px',
    },
    {
      key: 'c-vitt',
      width: '166px',
    },
    {
      key: 'visai',
      width: '181px',
    },
    {
      key: 'kubota',
      width: '216px',
    },
    {
      key: 'arincare',
      width: '318px',
    },
    {
      key: 'gq',
      width: '126px',
    },
    {
      key: 'gat',
      width: '110px',
    },
    {
      key: 'globish',
      width: '245px',
    },
    {
      key: 'voguebeauty',
      width: '151px',
    },
    {
      key: 'vogue',
      width: '249px',
    },
  ]

  const otherSections = [
    {
      title: 'Careers',
      href: '/careers',
      body: 'ร่วมงานกับเรา สร้างสรรค์สิ่งใหม่ ๆ และเติบโตไปด้วยกัน',
    },
    {
      title: 'AI Consultation',
      href: '/ai-consultation',
      body: 'ยกระดับธุรกิจของคุณสู่อีกขั้นด้วย Tailor-Made AI Solutions',
    },
    {
      title: 'Contact',
      href: '/contact',
      body: 'พร้อมให้คำปรึกษาทุกบริการจากผู้เชี่ยวชาญ และทีมงานคุณภาพที่คอยช่วยเหลือคุณ',
    },
  ]

  React.useEffect(() => {
    let typing
    let id = window.setTimeout(() => {
      typing = typewriter(preRef.current)
      typing.type()
    }, 1000)

    return () => {
      typing && typing.destroy()
      window.clearTimeout(id)
    }
  }, [])

  return (
    <React.Fragment>
      <Meta
        title='ผู้ให้บริการ Data Science Consultant ชั้นนำในไทย | Data Wow'
        description='Data Wow ผู้พัฒนาซอฟต์แวร์ AI และ Data Science ชั้นนำในไทย ให้บริการครอบคลุมทั้ง Big Data, AI Software, Web Software Development และ Data Management'
      />

      <div className='Index'>
        <div className='container'>
          {/* hero */}
          <section>
            <div className='Index__hero'>
              <h1>Data Wow ผู้ให้บริการ AI Software Development ชั้นนำในไทย</h1>
              <h2>
                ปรับกลยุทธ์ พัฒนาธุรกิจ ด้วยเทคโนโลยีอัจฉริยะ{' '}
                <span>Intelligent Composable Business</span> ร่วมกับ{' '}
                <span>Data Wow</span>
              </h2>
              <p>
                <strong>Data Wow</strong> คือ
                ผู้นำด้านการบริหารข้อมูลที่หลากหลาย เราเป็นบริษัทพัฒนา AI
                ในไทยมีบริการที่ครอบคลุม พร้อมด้วยเหล่า{' '}
                <strong>Data Expert Team</strong> ที่มีความชำนาญในการสร้างโมเดล
                AI ไม่ว่าจะเป็น <strong>Machine Learning, Deep Learning</strong>{' '}
                หรือ <strong>AI Engineering</strong>{' '}
                อย่างการตรวจสอบความถูกต้องของข้อมูลด้วยการทำ{' '}
                <strong>Data Validation</strong> หรือจะเป็นการใช้{' '}
                <strong>AI Prediction Model</strong>{' '}
                เข้ามาช่วยลดระยะเวลาการแก้ไขปัญหาต่าง ๆ
              </p>

              {/* h4 */}
              <div className='Index__face-detector'>
                <pre
                  ref={preRef}
                  dangerouslySetInnerHTML={{__html: codeString}}
                />

                <div>
                  <picture>
                    <source
                      srcSet='/images/face-detector.webp, /images/face-detector@2x.webp 2x'
                      type='image/webp'
                    />
                    <source
                      srcSet='/images/face-detector.png, /images/face-detector@2x.png 2x'
                      type='image/png'
                    />
                    <img
                      className='Index__face-detector__img'
                      src='/images/face-detector.png'
                      srcSet='/images/face-detector@2x.png 2x'
                      alt='Woman'
                      loading='lazy'
                      width='495'
                      height='584'
                    />
                  </picture>

                  <img
                    src='images/detect-area.svg'
                    alt='Detect area'
                    className='Index__face-detector__area'
                    loading='lazy'
                    width='72'
                    height='72'
                  />
                </div>
              </div>
            </div>
          </section>

          {/* services */}
          <section>
            <h2>
              เราเป็นผู้นำด้านการจัดการข้อมูล <span>ถูกต้องและปลอดภัย</span>
            </h2>

            <p>
              เปลี่ยนความยุ่งยากในการทำงานให้ง่าย รวดเร็ว แม่นยำ
              และปลอดภัยมากยิ่งขึ้น ด้วยบริการดี ๆ จาก Data Wow
              บริษัทผู้พัฒนาซอฟต์แวร์ AI ในไทย
              ที่พร้อมให้บริการคุณในทุกด้านการทำงาน ผ่านระบบซอฟต์แวร์ AI
              ที่ได้รับการพัฒนามาเป็นอย่างดีเพื่อให้เหมาะกับความต้องการในการใช้งานภายในองค์กรธุรกิจ
            </p>
          </section>

          {/* features */}
          <section>
            <Card>
              {services.map((service, index) => (
                <CardItem key={index} {...service} />
              ))}
            </Card>
          </section>

          {/* projects */}
          <section>
            <h2>
              บริการให้คำปรึกษาด้าน AI{' '}
              <span>และพัฒนาซอฟต์แวร์สำหรับแพลตฟอร์มต่าง ๆ</span>
            </h2>

            <p>
              ด้วยความเชี่ยวชาญในการพัฒนาแพลตฟอร์มให้เหมาะสมกับการทำงานในทุกอุตสาหกรรม
              Data Wow ผู้พัฒนาซอฟต์แวร์ AI และให้บริการ Data Consulting
              ในไทยมีบริการหลากหลายรูปแบบ รวมถึงบริการให้คำปรึกษาด้าน AI
              เพื่อช่วยสร้างระบบปฏิบัติการขององค์กรให้ทำงานอย่างมีประสิทธิภาพมากยิ่งขึ้นด้วย
            </p>
          </section>

          <section>
            <FeatureProjects />
          </section>

          {/* clients */}
          <section>
            <div className='Index__client'>
              <p>
                เราจะไม่หยุด<strong>พัฒนาสิ่งใหม่ ๆ</strong> เพื่อส่งมอบ
                <strong>บริการที่ดีที่สุดให้กับองค์กร</strong>ของคุณ
              </p>

              <div>
                <ul>
                  {clients.map((client) => (
                    <li key={client.key} style={{width: client.width}}>
                      <picture>
                        <source
                          type='image/webp'
                          srcSet={`/images/clients/${client.key}.webp, /images/clients/${client.key}2x.webp 2x`}
                        />
                        <img
                          srcSet={`/images/clients/${client.key}2x.png 2x`}
                          src={`/images/clients/${client.key}.png`}
                          alt={client.key}
                          loading='preload'
                        />
                      </picture>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          {/* contact */}
          <section>
            <div className='Index__contact'>
              <h2>
                มาร่วมเปลี่ยนแปลงและสร้างสิ่งใหม่ ๆ ที่ไม่เคยลองมาก่อน
                กับเราได้ที่นี่
              </h2>
              <p>
                หากคุณกำลังมองหาบริษัทเกี่ยวกับ AI ผู้พัฒนา Data Science{' '}
                หรือผู้ให้บริการ AI Software Development ในไทย Data Wow{' '}
                พร้อมมอบบริการให้คำปรึกษาด้าน AI ทั้งเรื่อง AI Software{' '}
                และระบบการจัดเก็บข้อมูล{' '}
                เพื่อนำไปปรับใช้ในองค์กรและอุตสาหกรรมของคุณได้อย่างเหมาะสมกับความต้องการ{' '}
                สามารถติดต่อกับเราได้ทันที ทีมงานของเรารอคุณอยู่{' '}
                <a href='mailto:sales@datawow.io'>sales@datawow.io</a>
              </p>
            </div>
          </section>

          {/* blog */}
          <section>
            <BlogCarousel />
          </section>

          {/* others */}
          <section>
            <div className='Index__others'>
              {otherSections.map(({title, body, href}) => (
                <div key={title}>
                  <h3>
                    <Link href={href}>
                      <a
                        title={title}
                        onClick={() => {
                          logEvent({
                            ga: {
                              category: 'Landing Page',
                              action: `Click ${title}`,
                            },
                          })
                        }}
                      >
                        {title}
                      </a>
                    </Link>
                  </h3>

                  <p>{body}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

Index.getLayout = function getLayout(page) {
  return <MainLayout>{page}</MainLayout>
}
