import * as React from 'react'
import Slider from 'react-slick'
import Link from 'next/link'

import {axios} from '@lib/helpers'
import {logEvent} from '@lib/gtm'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type Post = {
  id: number
  image: string
  title: string
  slug: string
}

function transformer(data: any): Post {
  return {
    id: data.id,
    image: data.image_1?.url,
    title: data.th_title,
    slug: data.th_slug,
  }
}

const BlogCarousel = () => {
  const [posts, setPosts] = React.useState<Post[]>([])

  const settings = {
    className: 'center',
    infinite: true,
    dot: false,
    speed: 400,
    centerPadding: '60px',
    slidesToShow: 4,
    swipeToSlide: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  React.useEffect(() => {
    let mounted = true
    axios.get('/th_blogs').then((res) => {
      mounted && setPosts(res.data.data.map(transformer))
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className='Index__blog'>
      <h2>Blogs</h2>

      <div className='Index__blog__container'>
        <Slider {...settings}>
          {posts.map((item) => {
            return (
              <div key={item.slug}>
                <Link href={`/blogs/${item.slug}`}>
                  <a title={item.title} className='Index__blog__item'>
                    <div className='Index__blog__item__aspect-ratio'>
                      <img
                        src={item.image}
                        alt={item.title}
                        loading='lazy'
                        className='Index__blog__item__img'
                      />
                    </div>
                    <span className='Index__blog__item__description'>
                      {item.title}
                    </span>
                  </a>
                </Link>
              </div>
            )
          })}
        </Slider>

        <Link href='/blogs'>
          <a
            className='Button'
            title='Blogs'
            onClick={() => {
              logEvent({
                ga: {
                  category: 'Landing Page',
                  action: 'Click View Other Blogs',
                },
              })
            }}
          >
            อ่านบทความอื่น ๆ
          </a>
        </Link>
      </div>
    </div>
  )
}

type SlideNavProps = {
  onClick?: () => void
}

const NextArrow = ({onClick}: SlideNavProps) => {
  return (
    <div
      className='Index__blog__arrow Index__blog__arrow--next'
      onClick={onClick}
    >
      <svg
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M9 5l7 7-7 7'
        ></path>
      </svg>
    </div>
  )
}

const PrevArrow = ({onClick}: SlideNavProps) => {
  return (
    <div
      className='Index__blog__arrow Index__blog__arrow--prev'
      onClick={onClick}
    >
      <svg
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M15 19l-7-7 7-7'
        ></path>
      </svg>
    </div>
  )
}

export {BlogCarousel}
